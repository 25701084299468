<template>
  <div class="page">
    <div class="container head">
      <div class="title">{{ticketDetail.name}}</div>
      <div class="line"></div>
    
      <!--使用日期-->
      <div class="date-box">
        <!--TODO: 日期使用 vant https://vant-contrib.gitee.io/vant-weapp/#/calendar-->
        <div class="subtitle">使用日期</div>
        <div class="list flexcenter">
          <div class="item active flexcenter"
               v-for="(item, idx) in showDate"
               :key="item.id"
          >
            <div class="forf">
              <div>{{date.date_name}}</div>
              <div>￥{{date.price}}</div>
            </div>
          </div>
          <div class="item flexcenter">
            <div class="forf">
              <div>明天12-28</div>
              <div>￥280起</div>
            </div>
          </div>
          <div class="item disable flexcenter">
            <div class="forf">
              <div>周二12-29</div>
              <div>不可预订</div>
            </div>
          </div>
          <div class="item flexcenter" @click="isShowCalendar=true">
            <div class="forf">
              <div style="text-align: center">></div>
              <div>更多日期</div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="xuzhi flexcenter">
        <div class="left">需携带身份证</div>
        <div class="right">购买须知 ></div>
      </div>
      <div class="line"></div>
    
      <!--选择数量-->
      <div class="count-box">
        <div class="subtitle">选择数量</div>
        <div class="piao-box flexcenter">
          <div class="left">成人票</div>
          <div class="right flexcenter">
            <span class="total">￥320</span>
            <i class="iconfont iconjian2"></i>
            <span class="count">1</span>
            <i class="iconfont iconjia2"></i>
          </div>
        </div>
        <div class="desc">每类票种限购5张</div>
      </div>
    </div>
  
    <!--游客信息-->
    <div class="container customer">
      <div class="subtitle">
        <span>游客1</span>
        <span class="desc">需填 1 位，用于入园身份证验证</span>
      </div>
      <van-cell-group>
        <van-field
            v-model="username"
            clearable
            label="姓名"
            label-width="7em"
            placeholder="必填，请输入姓名"
        />
    
        <van-field
            v-model="username"
            clearable
            label="手机号"
            label-width="7em"
            placeholder="必填，请输入手机号"
        />
    
        <van-field
            v-model="username"
            clearable
            label="身份证"
            label-width="7em"
            placeholder="必填，请输入身份证"
        />
      </van-cell-group>
    </div>
    <!--游客信息-->
    <div class="container customer">
      <div class="subtitle">
        <span>游客2</span>
        <!--<span class="desc">需填 1 位，用于入园身份证验证</span>-->
      </div>
      <van-cell-group>
        <van-field
            v-model="username"
            clearable
            label="姓名"
            label-width="7em"
            placeholder="必填，请输入姓名"
        />
    
        <van-field
            v-model="username"
            clearable
            label="手机号"
            label-width="7em"
            placeholder="必填，请输入手机号"
        />
    
        <van-field
            v-model="username"
            clearable
            label="身份证"
            label-width="7em"
            placeholder="必填，请输入身份证"
        />
      </van-cell-group>
    </div>
  
    <!--优惠券信息-->
    <div class="container discount">
      <div class="subtitle">优惠</div>
      <div class="desc flexcenter">
        <div class="left">优惠券</div>
        <div class="right on" @click="isShowCoupon=true">2张可用</div>
        <!--<div class="right off">暂无优惠券</div>-->
      </div>
    </div>
  
    <div class="bottom flexcenter">
      <div></div>
      <div class="cashier flexcenter">
        <div class="heji">合计: </div>
        <div class="price">￥900</div>
        <div class="buy">立即支付</div>
      </div>
    </div>
  
    <!--优惠券弹窗-->
    <van-popup
        v-model="isShowCoupon"
        position="bottom"
        closeable
    >
      <div class="coupon-box">
        <div class="title-box flexcenter">
          <div></div>
          <div class="title">优惠券</div>
          <i class="iconfont iconguanbi" @click="isShowCoupon=false"></i>
        </div>
        <div class="list" scroll-y="true">
          <!--
          <div class="list-inner flexcenter">
            <div class="img-box" data-idx="0" bindtap="chooseCoupon">
              <image class="img" mode="widthFix" src="../../images/coupon50.png"></image>
              <image hidden="{{couponIndex !== 0}}" class="chosen" mode="widthFix" src="../../images/icon/chosen.png"></image>
            </div>
            <div class="img-box" data-idx="1" bindtap="chooseCoupon">
              <image class="img" mode="widthFix" src="../../images/coupon50.png"></image>
              <image hidden="{{couponIndex !== 1}}" class="chosen" mode="widthFix" src="../../images/icon/chosen.png"></image>
        </div>
      </div>
      -->
          <div class="list-inner-new">
            <coupon1 :spotwhite="true"></coupon1>
            <coupon1 :spotwhite="true"></coupon1>
          </div>
        </div>
        <div @click="confirmCoupon" class="confirm">确定</div>
      </div>
    </van-popup>
  
    <!--使用日期弹窗-->
    <van-calendar
        title="使用日期"
        v-model="isShowCalendar"
        :formatter="formatter"
        @close="isShowCalendar=false"
        @confirm="confirmCalendar"
    />
  </div>
</template>

<script>
  import coupon1 from 'components/coupon1'
  
  export default {
    name: 'mpjs',
    data() {
      return {
        username: '',
        isShowCoupon: false,
        date: '',
        idindex: 0,
        idarray: ['身份证', '驾驶证'],

        isCouponShow: false,
        couponIndex: -1,
        loading:false,
        isShowCalendar: false,
        ticketNumber:1,
        guestList:[{name:'',id_type:'1',id_number:'',phone:''}],
        dateArr:[],
        currentPs:[],
        showDate:[],
        ticketDetail:[],
        orderAmount:'0.00',
        contact_name:'',
        contact_phone:'',
        contact_email:'',
      }
    },
    components: {
      coupon1,
    },
    created() {
      let tid = this.$route.query.tid
      this.getDetail(tid);
    },
    methods: {
      formatter(day) {
        let now = new Date()
        let now0 = new Date(now.toDateString())
        if (day.date.getTime() < now0.getTime()) {
          day.bottomInfo = ''
        } else {
          day.bottomInfo = '￥280起'
        }

        return day


        if (month === 5) {
          if (date === 1) {
            day.topInfo = '劳动节';
          } else if (date === 4) {
            day.topInfo = '五四青年节';
          } else if (date === 11) {
            day.text = '今天';
          }
        }

        if (day.type === 'start') {
          day.bottomInfo = '入住';
        } else if (day.type === 'end') {
          day.bottomInfo = '离店';
        }

        return day;
      },
      confirmCoupon(event){
        this.isShowCoupon = false
      },
      formatDate(date) {
        date = new Date(date);
        return `${date.getMonth() + 1}/${date.getDate()}`;
      },
      confirmCalendar(event) {
        console.log('event', event)
        this.isShowCalendar = false
        this.date = this.formatDate(event.detail)
      },
      /**
       * 获取门票详情
       */
      getDetail(tid){
        let that = this;
        this.loading = true
        this.post(
          this.API.getTicketsDetail,
          {tid:tid}
        ).then(response => {
          let ticketDetail = response.data.detail;
          let userInfo = response.data.userInfo;
          console.log(userInfo);
          let dateRes = ticketDetail['stockPrice']['data'];
          let _dateArr = [];
          for(let j = 0,len=dateRes.length; j < len; j++) {
            let _date = new Date(dateRes[j]['date']+" 00:00:00");

            _dateArr[_date.getTime()] = {
              index:j,
              price:dateRes[j]['price'],
              stock:dateRes[j]['stock']
            }
          }

          this.contact_name =  userInfo.nickname
          this.contact_phone =  userInfo.phone
          this.ticketDetail =  ticketDetail
          this.dateArr = _dateArr
          this.currentPs = {
           price:ticketDetail['stockPrice']['data'][0]['price'],
           date:ticketDetail['stockPrice']['data'][0]['date']
          }
          this.orderAmount = ticketDetail['stockPrice']['data'][0]['price']
          this.showDate = ticketDetail['stockPrice']['data'].slice(0,3)
          this.formatter = function(day) {

            if(_dateArr[day.date.getTime()])
            {
              let _d = _dateArr[day.date.getTime()];
              day.bottomInfo = '￥'+_d.price;
            }
            else{
              day.bottomInfo = ''
              day.type = 'disabled'
            }

            return day
          }
          this.loading = false
        })
      },
      /**
       * 创建订单
       */
      createOrder(){
        let that = this;
        let orderInfo = {};
        orderInfo.ticket_id = this.data.ticketDetail.id;
        orderInfo.ticket_date = this.data.currentPs.date;
        orderInfo.pay_type = 7;
        orderInfo.contact_name = this.data.contact_name;
        orderInfo.contact_phone = this.data.contact_phone;
        orderInfo.contact_email = this.data.contact_email;
        orderInfo.guest = this.data.guestList;

        // 查看是否授权
        wx.getSetting({
          success (res){
            if (res.authSetting['scope.userInfo']) {
              wx.showLoading({
                title: '正在处理,请稍等',
                mask:true,
              })
              wx.login({
                success (res) {
                  let code = res.code;
                  wx.getUserInfo({
                    success: function(res) {
                      orderInfo.iv = res.iv;
                      orderInfo.encryptedData = res.encryptedData;
                      orderInfo.code = code;

                      this.loading = true
                      this.post(
                        'index.php/wap/weapp.Tickets/createOrder',
                        orderInfo,
                        function (response) {
                          console.log(response);
                          if(response.data.err=='1')
                          {
                            console.log("我走到了这里2222");
                            wx.showToast({
                              title: response.data.msg,
                              icon:'none'
                            })
                            return false;
                          }
                          else
                          {
                            console.log("我走到了这里");
                            let trans_no = response.data.paydata.data.trans_no;
                            wx.requestPayment({
                              timeStamp: response.data.paydata.data.timeStamp,
                              nonceStr: response.data.paydata.data.nonceStr,
                              package: response.data.paydata.data.package,
                              signType: response.data.paydata.data.signType,
                              paySign: response.data.paydata.data.paySign,
                              success (res) {
                                that.checkPay(trans_no,res);
                              },
                              fail (res) {
                                that.cancelPay(res);
                              }
                            })
                          }
                          that.setData({loading:false});
                        },
                        function (error) {
                          that.setData({loading:false});
                        }
                      );
                    },
                    fail(){
                      wx.showToast({
                        title:"请重新登录",
                        icon:'none'
                      });
                    }
                  });
                },
                fail(res){
                  wx.showToast({
                    title:"请重新登录",
                    icon:'none'
                  });
                }
              });
            }
            else
            {
              wx.showToast({
                title: '用户未授权,请重新登录',
                icon:'none'
              })
              setTimeout(function(){
                wx.redirectTo({url: "/pages/login/index"});
              },500)
            }
          },
          fail(res){
            wx.showToast({
              title:"请重新登录",
              icon:'none'
            });
            setTimeout(function(){
              wx.redirectTo({url: "/pages/login/index"});
            },500)
          }
        });
      },
      checkPay:function(trans_no,res) {
        this.post(
          'index.php/wap/common.cashier/checkPay',
          {trans_no:trans_no},
          function (response) {
            if(response.data.err=='0')
            {
              wx.showToast({
                title: '支付成功',
                icon:'none'
              })
            }else{
              wx.showToast({
                title: response.data.msg,
                icon:'none'
              })
            }
            wx.redirectTo({
              url: '/pages/mytickets/mytickets',
            })
          }
        );
      },
      cancelPay:function(res){
        if(res.errMsg=='requestPayment:fail cancel')
        {
          wx.showToast({
            title: '您取消了支付',
            icon:'none'
          })

          wx.redirectTo({
            url: '/pages/mytickets/mytickets',
          })
        }
      },
      /**
       * 切换时间
       */
      changeCurrent(e){
        let date = e.currentTarget.dataset.date;
        let price = e.currentTarget.dataset.price;

          this.currentPs = {
            price:price,
            date:date,
          }
          this.orderAmount = this.ticketNumber*(price*100)/100
      },
      chooseCoupon(event){
        let idx = event.currentTarget.dataset.idx

        this.couponIndex = +idx
      },
      confirmCoupon(event){
        this.hideCouponBox()
      },
      showCouponBox(){
        this.isCouponShow = true
      },
      hideCouponBox(){
        this.isCouponShow = false
      },
      killMask(){
        this.isCouponShow = false
      },
      showCalendar() {
        console.log('点击 更多日期')
        this.isShowCalendar = true
      },
      closeCalendar() {
        this.isShowCalendar = false
      },
      formatDate(date) {
        date = new Date(date);
        return `${date.getMonth() + 1}/${date.getDate()}`;
      },
      confirmCalendar(event) {
        let that = this;
        console.log(this.data.dateArr);
        let selectDate = this.data.dateArr[new Date(event.detail).getTime()];
          this.isShowCalendar=  false
          this.showDatethat.data.ticketDetail['stockPrice']['data'].slice(selectDate.index,selectDate.index+3)
          this.currentPs = {
            price:that.data.ticketDetail['stockPrice']['data'][selectDate.index]['price'],
            date:that.data.ticketDetail['stockPrice']['data'][selectDate.index]['date'],
          }
          this.orderAmount = that.data.ticketNumber*(that.data.ticketDetail['stockPrice']['data'][selectDate.index]['price']*100)/100,

        console.log(selectDate);
      },
      jiaNum(){
        if(this.data.ticketNumber<this.data.ticketDetail['max_qty'])
        {
          console.log(this.data.guestList);
            this.orderAmount = ((this.data.ticketNumber+1)*(this.data.currentPs.price*100))/100
            this.ticketNumber = this.data.ticketNumber+1
            this.guestList = this.data.guestList.concat({name:'',id_type:'1',id_number:'',phone:''})
        }else{
          this.$toast('已达上限')
        }
      },
      jianNum(){
        if(this.data.ticketNumber>this.data.ticketDetail['min_qty'])
        {
          let _guestList = this.data.guestList;
          _guestList.pop();
            this.orderAmount = ((this.data.ticketNumber-1)*(this.data.currentPs.price*100))/100
            this.ticketNumber = this.data.ticketNumber-1
            this.guestList = _guestList
        }else{
          this.$toast('已达下限')
        }
      },
      //双向绑定input数据
      bindInputData:function(e){
        let _this = this;
        let dataset = e.currentTarget.dataset;
        let value = e.detail.value;
        let idx = dataset.idx;
        let name = dataset.name;

        let _guestList = this.data.guestList;
        _guestList[idx][name] = value;

        _this.guestList = _guestList

      },
      goBackPage:function() {
        this.$router.back()
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
    font-size: 32px;
    line-height: 1.6;
    font-family: "PingFang SC";
  }

  .container {
    margin: 24px;
    padding: 24px;
    background-color: #FFFFFF;
    border-radius: 6px;
  }

  .subtitle{
    margin-bottom: 32px;
    font-size: 32px;
    font-weight: bold;
    color: #191919;
  }

  .head .title {
    margin-top: 8px;
    font-size: 36px;
    font-weight: bold;
    color: #191919;
  }
  .head .line {
    margin: 32px 0;
    height: 2px;
    background-color: #EEEEEE;
  }
  .head .date-box .list {
    justify-content: space-between;
  }
  .head .date-box .list .item {
    width: 152px;
    height: 124px;
    border: 2px solid #E9E9E9;
    border-radius: 7px;
    color: #191919;
    font-size: 28px;
  }
  .head .date-box .list .active {
    border-color: #F8D414;
    background-color: #FFF6C6;
  }
  .head .date-box .list .disable {
    background-color: #F5F5F5;
    color: #C7C7C7;
  }
  .head .date-box .list .item .forf {
    padding-left: 12px;
  }
  .head .xuzhi {
    justify-content: space-between;
    margin-top: 24px;
    padding: 0 24px;
    height: 55px;
    background-color: rgba(254,163,15,0.1);
    border-radius: 8px;
    font-size: 24px;
  }
  .head .xuzhi .left {
    color: #F84F41;
  }
  .head .xuzhi .right {
    color: #FEA30F;
  }
  .head .count-box .piao-box {
    justify-content: space-between;
    font-size: 28px;
  }
  .head .count-box .piao-box .right .total {
    margin-right: 40px;
    color: #F84F41;
  }
  .head .count-box .piao-box .right .count {
    width: 80px;
    text-align: center;
  }
  .head .count-box .desc {
    margin-top: 24px;
    margin-bottom: 8px;
    padding-left: 16px;
    height: 55px;
    line-height: 55px;
    background-color: #F5F5F5;
    border-radius: 8px;
    font-size: 24px;
    color: #777777;
  }

  .customer .subtitle .desc {
    margin-left: 74px;
    font-size: 24px;
    font-weight: normal;
    color: #777777;
  }
  .customer .form .item {
    height: 102px;
    line-height: 102px;
    border-bottom: 2px solid #EEEEEE;
    font-size: 28px;
    color: #191919;
  }
  .customer .form .item:last-child {
    border-bottom: none;
  }
  .customer .form .item .left {
    width: 140px;
  }

  .discount {
    margin-bottom: 140px;
  }
  .discount .desc {
    padding-bottom: 160px;
    justify-content: space-between;
    font-size: 28px;
    color: #191919;
  }
  .discount .desc .on {
    color: #F84F41;
  }
  .discount .desc .off {
    color: #777777;
  }

  .bottom {
    justify-content: space-between;
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    height: 110px;
    background-color: #FFFFFF;
  }
  .bottom .heji {
    font-size: 24px;
    color: #191919;
  }
  .bottom .price {
    margin-right: 40px;
    font-size: 30px;
    color: #F84F41;
  }
  .bottom .buy {
    margin-right: 30px;
    width: 192px;
    height: 72px;
    line-height: 72px;
    border-radius: 36px;
    background-color: #FCC601;
    text-align: center;
    font-size: 30px;
    color: #FFFFFF;
  }

  .graybg {
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
  }

  .coupon-box {
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1034px;
    padding: 30px;
    border-radius: 20px 20px 0 0;
    background-color: #FFFFFF;
  }
  .coupon-box .title-box {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 56px;
  }
  .coupon-box .title-box .iconfont {
    margin-right: 30px;
    font-size: 24px;
  }
  .coupon-box .list {
    height: 650px;
  }
  .coupon-box .list .list-inner {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .coupon-box .list .list-inner .img-box {
    position: relative;
    width: 330px;
    margin-bottom: 30px;
  }
  .coupon-box .list .list-inner .img-box .img {
    display: block;
    width: 100%;
  }
  .coupon-box .list .list-inner .img-box .chosen {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 64px;
  }

  .confirm {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 28px;
    margin: 0 30px;
    height: 80px;
    line-height: 80px;
    border-radius: 40px;
    background-color: #FCC601;
    text-align: center;
    color: #FFFFFF;
  }


  .van-calendar__bottom-info {
    color: #F84F41!important;
  }
  .van-calendar__selected-day {
    width: 1.42rem!important;
    height: 0.92rem!important;
    border: 2px solid #F8D414;
    border-radius: 6px;
    font-weight: bold;
    color: #191919!important;
    background-color: #FFF6C6!important;
  }
  .van-button--danger {
    background-color: #F8D414!important;
    border-color: transparent!important;
  }
  .van-calendar__weekday:first-child, .van-calendar__weekday:last-child {
    color: #F8D414!important;
  }

</style>
